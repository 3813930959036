import { Input as AntInput } from "antd";
import { motion } from "framer-motion";

export default function Input({
  label,
  placeholder,
  className,
  onChange,
  validationErrors,
  inputClassname,
  variant = "filled",
  ...props
}) {
  return (
    <div className={`${className}`} aria-disabled={props.disabled}>
      <label
        htmlFor={props.name}
        className="font-medium whitespace-nowrap mb-1 block"
      >
        {label}
      </label>
      <AntInput
        placeholder={placeholder}
        variant={variant}
        onChange={onChange}
        className={inputClassname}
        size="large"
        status={
          props.value
            ? undefined
            : validationErrors?.[props.name]
            ? "error"
            : undefined
        }
        {...props}
      />
      {validationErrors?.[props.name] && (
        <motion.div
          className="h-5 overflow-hidden"
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <motion.span
            className="text-red-500 text-sm block"
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.4, ease: "easeOut" }}
          >
            {validationErrors[props.name]}
          </motion.span>
        </motion.div>
      )}
    </div>
  );
}
